export const countryCodes = {
  Abkhazia: '+7',
  Afghanistan: '+93',
  Albania: '+355',
  Algeria: '+213',
  Andorra: '+376',
  Angola: '+244',
  'Antigua and Barbuda': '+1 (268)',
  Argentina: '+54',
  Armenia: '+374',
  Azerbaijan: '+994',
  Australia: '+61',
  AustralianAntarcticTerritory: '+672',
  AustralianExternalTerritories: '+672',
  Austria: '+43',
  Bahamas: '+1-242',
  Bahrain: '+973',
  Bangladesh: '+880',
  Barbados: '+1-246',
  Belarus: '+375',
  Belgium: '+32',
  Belize: '+501',
  Benin: '+229',
  Bhutan: '+975',
  Bolivia: '+591',
  'Bosnia and Herzegovina': '+387',
  Botswana: '+267',
  Brazil: '+55',
  Brunei: '+673',
  Bulgaria: '+359',
  'Burkina Faso': '+226',
  Burundi: '+257',
  Cambodia: '+855',
  Cameroon: '+237',
  Canada: '+1',
  'Cape Verde': '+238',
  'Central African Republic': '+236',
  Chad: '+235',
  Chile: '+56',
  China: '+86',
  ChristmasIsland: '+61 (8 9164)',
  CocosIslands: '+61 (8 9162)',
  Colombia: '+57',
  Comoros: '+269',
  'Congo (Congo-Brazzaville)': '+242',
  'Côte d Ivoire': '+225',
  CookIslands: '+682',
  'Costa Rica': '+506',
  Croatia: '+385',
  Cuba: '+53',
  Cyprus: '+357',
  'Czechia (Czech Republic)': '+420',
  'Democratic Republic of the Congo': '+243',
  Denmark: '+45',
  Djibouti: '+253',
  Dominica: '+1-767',
  'Dominican Republic': '+1-809',
  EastTimor: '+670',
  Ecuador: '+593',
  Egypt: '+20',
  'El Salvador': '+503',
  'Equatorial Guinea': '+240',
  Eritrea: '+291',
  'Eswatini (fmr. Swaziland)': '+268',
  Ethiopia: '+251',
  FalklandIslands: '+500',
  FederatedStatesOfMicronesia: '+691',
  Fiji: '+679',
  Finland: '+358',
  France: '+33',
  FrenchGuiana: '+594',
  FrenchPolynesia: '+689',
  Gabon: '+241',
  Gambia: '+220',
  Georgia: '+995',
  Germany: '+49',
  Ghana: '+233',
  Greece: '+30',
  Greenland: '+299',
  Grenada: '+1473',
  Guadeloupe: '+590',
  Guatemala: '+502',
  Guinea: '+224',
  'Guinea-Bissau': '+245',
  Guyana: '+592',
  Haiti: '+509',
  Honduras: '+504',
  'Hong Kong': '+852',
  'Holy See': '+39',
  Hungary: '+36',
  Iceland: '+354',
  India: '+91',
  Indonesia: '+62',
  Inmarsat: '+870',
  Iran: '+98',
  Iraq: '+964',
  Ireland: '+353',
  Israel: '+972',
  Italy: '+39',
  IvoryCoast: '+225',
  Jamaica: '+1876',
  Japan: '+81',
  Jordan: '+962',
  Kazakhstan: '+7',
  Kenya: '+254',
  Kiribati: '+686',
  Kuwait: '+965',
  Kyrgyzstan: '+996',
  Laos: '+856',
  Lesotho: '+266',
  Liberia: '+231',
  Libya: '+218',
  Macau: '+853',
  Madagascar: '+261',
  Malawi: '+265',
  Malaysia: '+60',
  Mali: '+223',
  'Marshall Islands': '+692',
  Martinique: '+596',
  Mauritania: '+222',
  Mauritius: '+230',
  Mexico: '+52',
  Morocco: '+212',
  Mozambique: '+258',
  'Myanmar (formerly Burma)': '+95',
  Namibia: '+264',
  Nauru: '+674',
  Netherlands: '+31',
  NetherlandsAntilles: '+599',
  NewCaledonia: '+687',
  'New Zealand': '+64',
  Nicaragua: '+505',
  Niger: '+227',
  Nigeria: '+234',
  Niue: '+683',
  NorfolkIsland: '+672',
  'North Korea': '+850',
  'North Macedonia': '+389',
  Norway: '+47',
  Pakistan: '+92',
  Palau: '+680',
  'Palestine State': '+970',
  Panama: '+507',
  'Papua New Guinea': '+675',
  Paraguay: '+595',
  Peru: '+51',
  Philippines: '+63',
  PitcairnIslands: '+64',
  Poland: '+48',
  Reunion: '+262',
  Romania: '+40',
  Russia: '+7',
  Rwanda: '+250',
  'Saint Kitts And Nevis': '+1',
  'Saint Lucia': '+1 758',
  'Saint Vincent and the Grenadines': '+1 784',
  SaintPierreAndMiquelon: '+508',
  Samoa: '+685',
  'Sao Tome and Principe': '+239',
  'Saudi Arabia': '+966',
  Senegal: '+221',
  Seychelles: '+248',
  'Sierra Leone': '+232',
  Singapore: '+65',
  'Solomon Islands': '+677',
  Somalia: '+252',
  'South Africa': '+27',
  SouthGeorgiaAndTheSouthSandwichIslands: '+500',
  'South Korea': '+82',
  SouthOssetia: '+7',
  'South Sudan': '+211',
  Spain: '+34',
  'Sri Lanka': '+94',
  Sudan: '+249',
  Suriname: '+597',
  Swaziland: '+268',
  Sweden: '+46',
  Switzerland: '+41',
  Tanzania: '+255',
  Thailand: '+66',
  'Timor-Leste': '+670 ',
  Togo: '+228',
  Tokelau: '+690',
  Tonga: '+676',
  'Trinidad and Tobago': '+1 868',
  Tunisia: '+216',
  Turkey: '+90',
  Tuvalu: '+688',
  'United Arab Emirates': '+971',
  'United Kingdom': '+44',
  'United States of America': '+1',
  Uganda: '+256',
  Uruguay: '+598',
  Vanuatu: '+678',
  Venezuela: '+58',
  Vietnam: '+84',
  WallisAndFutuna: '+681',
  Zambia: '+260',
  Zimbabwe: '+263',
  Latvia: '+371',
  Lebanon: '+961',
  Liechtenstein: '+423',
  Lithuania: '+370',
  Luxembourg: '+352',
  Maldives: '+960',
  Malta: '+356',
  Micronesia: '+691',
  Moldova: '+373',
  Monaco: '+377',
  Mongolia: '+976',
  Montenegro: '+382',
  Nepal: '+977',
  Oman: '+968',
  Portugal: '+351',
  Qatar: '+974',
  'San Marino': '+378',
  Serbia: '+381',
  Slovakia: '+421',
  Slovenia: '+386',
  Syria: '+963',
  Tajikistan: '+992',
  Turkmenistan: '+993',
  Ukraine: '+380',
  Uzbekistan: '+998',
  Yemen: '+967',
};
